@font-face {
    font-family: "Suisse Intl Regular";
    font-style: normal;
    font-weight: 400;
    src: local("Suisse Intl"), local("Suisse Intl"), url("../fonts/Suisse Intl Regular.woff");
}

@font-face {
    font-family: "Suisse Intl Medium";
    font-style: normal;
    font-weight: 500;
    src: local("Suisse Intl"), local("Suisse Intl"), url("../fonts/Suisse Intl Medium.woff");
}

@font-face {
    font-family: 'Inter';
    src: local("Inter-Thin"), url("../fonts/Inter/Inter-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: 'normal';
    font-display: 'fallback';
}

@font-face {
    font-family: 'Inter';
    src: local("Inter-ExtraLight"), url("../fonts/Inter/Inter-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: 'normal';
    font-display: 'fallback';
}

@font-face {
    font-family: 'Inter';
    src: local("Inter-Light"), url("../fonts/Inter/Inter-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: 'normal';
    font-display: 'fallback';
}

@font-face {
    font-family: 'Inter';
    src: local("Inter-Regular"), url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: 'normal';
    font-display: 'fallback';
}

@font-face {
    font-family: 'Inter';
    src: local("Inter-Medium"), url("../fonts/Inter/Inter-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: 'normal';
    font-display: 'fallback';
}
@font-face {
    font-family: 'Inter';
    src: local("Inter-SemiBold"), url("../fonts/Inter/Inter-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: 'normal';
    font-display: 'fallback';
}
@font-face {
    font-family: 'Inter';
    src: local("Inter-Bold"), url("../fonts/Inter/Inter-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: 'normal';
    font-display: 'fallback';
}
@font-face {
    font-family: 'Inter';
    src: local("Inter-ExtraBold"), url("../fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: 'normal';
    font-display: 'fallback';
}
@font-face {
    font-family: 'Inter';
    src: local("Inter-Black"), url("../fonts/Inter/Inter-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: 'normal';
    font-display: 'fallback';
}